/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body,
#root {
  height: 100%;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}
/*
.ant-pro-top-nav-header-menu{
  position: absolute;
  width: 100%;
}*/
.top-nav-menu {
  justify-content: center;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
::-webkit-scrollbar {
  width: 8px;
  -webkit-appearance: none;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #707070;
}
::-webkit-scrollbar-thumb:hover {
  background: #355cc9;
}
::-webkit-overflow-scrolling:touch {
  width: 5px;
  background: #707070;
}
.ant-input,
.ant-picker,
.ant-select-selector,
button,
.ant-input-affix-wrapper {
  border-radius: 20px !important;
}
.ant-pro-global-header-layout-top > .ant-pro-global-header-collapsed-button {
  order: -1;
  margin: 0px 16px 0px 0px;
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-form-item-label > label {
  font-weight: 600;
}
.ant-pro-top-nav-header-menu {
  position: absolute;
  width: 100%;
}
.ant-pro-top-nav-header > div:last-child {
  right: 0px;
}
.ant-pro-top-nav-header-main > div:last-child {
  position: absolute;
  right: 0px;
}
.ant-checkbox-inner {
  border: 1px solid #1890ff !important;
}
.reset_border_radius .ant-input-wrapper input {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.reset_border_radius .ant-input-group-addon button {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}
